import React from 'react'
import styled from 'styled-components'

const ButtonContent = styled.button`
  cursor: ${props => props.disabled ? 'auto' : 'pointer'};
  border: none;
  background-color: ${props => props.theme.colors.tertiary};
  width: auto;
  min-width: ${props => props.minWidth ? props.minWidth : '119px'};
  min-height: 40px;
  text-align: center;
  border: 1px solid ${props => props.theme.colors.tertiary};

  font-family: Raleway;
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 140%;
  letter-spacing: 0.0456169px;
  text-align: center;

  text-decoration: none;
  color: white;

  transition: all 0.2s;
  display: flex;
  justify-content: center;
  // flex-direction: column;

  border-radius: ${props => props.borderRadius ? props.borderRadius : '4.54299px'};
  box-shadow: ${props => props.disabled ? '' : '2px 2px 10px rgba(0, 0, 0, 0.1)'};

  padding: ${props => props.padding ? props.padding : '0 20px'};

  &:hover {
    text-decoration: none;
    box-shadow: ${props => props.disabled ? '' : '2px 2px 10px rgba(0, 0, 0, 0.5)'};
  }

  &.rounded.tertiary {
    background-color: ${props => props.theme.colors.transparent};
    color: ${props => props.theme.colors.tertiary};
    border: 1px solid ${props => props.theme.colors.tertiary};
  }

  &.medium {
    font-size: 18px;
    font-weight: 28px;
    letter-spacing: 0.0727273px;
  }

  &.large {
    font-weight: 500;
    font-size: 23px;
    line-height: 30px;
    /* or 150% */

    letter-spacing: 0.14em;
  }

  &:focus {
    outline:0;
  }

  @media screen and (max-width: 600px) {
    &.responsive {
      font-size: 12px;
      font-weight: 25px;
      line-height: 110%;
      padding: 10px 10px;
      min-width: 20px;
      min-height: 30px;
    }
  }

  @media screen and (max-width: 500px) {
    &.responsive {
      font-size: 10px;
      font-weight: 20px;
      line-height: 110%;
      padding: 7px 10px;
      min-width: 20px;
      min-height: 20px;
    }
  }
`

const Button = ({
  children,
  className,
  minWidth,
  padding,
  borderRadius,
  type,
  name,
  value,
  id,
  disabled,
  onClick = () => {}
}) => {
  
  return(
    <ButtonContent 
      className={className} 
      onClick={onClick}
      minWidth={minWidth}
      padding={padding}
      borderRadius={borderRadius}
      name={name}
      value={value}
      id={id}
      type={type || 'button'}
      disabled={disabled}>
      {children}
    </ButtonContent>
  );
}

export default Button;