import React from 'react'
// import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components'
import Helmet from 'react-helmet'
// import favicon from '../images/favicon.ico'
import '../styles/global'
import theme from '../styles/theme'
import config from '../utils/siteConfig'
import Menu from '../components/Menu'
import Footer from '../components/Footer'
// import Overlay from '../components/Overlay'

// const Template = ({ children, whiteLgNav, whiteNav, showRegister }) => {

class Template extends React.Component {
  
  constructor(props) {
    super(props)
    this.menuContainerRef = React.createRef();
  }

  render() {
    const { children, whiteLgNav, whiteNav, showRegister } = this.props
    
    return (
      <div className="siteRoot">
        <Helmet>
          <title>{config.siteTitle}</title>
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          {/* <link rel="icon" href={favicon} /> */}
          <link rel="icon" href='/favicon.ico' />
          <meta name="description" content={config.siteDescription} />
          <meta property="og:title" content={config.siteTitle} />
          <meta property="og:url" content={config.siteUrl} />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="website" />
          <meta property="og:site_name" content={config.siteTitle} />

          <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png"/>
          <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
          <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>
          <link rel="manifest" href="/site.webmanifest"/>
          <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5"/>
          <meta name="msapplication-TileColor" content="#da532c"/>
          <meta name="theme-color" content="#ffffff"/>
        </Helmet>

        <ThemeProvider theme={theme}>
          <>
            <div className="siteContent">
              {/* <Overlay /> */}
              <Menu whiteLgNav={whiteLgNav} whiteNav={whiteNav} showRegister={showRegister} ref={this.menuContainerRef}/>
              {children}
            </div>
            <Footer />
          </>
        </ThemeProvider>
      </div>
    )
  }
}

/* const rootElement = document.getElementById('root');
ReactDOM.render(<Template variants={testArray} />, rootElement); */

export default Template
