import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { Flex, Box } from '@rebass/grid'
import theme from '../styles/theme'
import addToMailchimp from 'gatsby-plugin-mailchimp'

const Wrapper = styled.footer`
  background: ${props => props.theme.colors.tan};
  width: 100%;
  padding: 2em 0;

  @media screen and (max-width: 630px) {
    padding: 2em 2em;
  }
`
const Column = styled(Box)`
  padding: ${ props => props.padding ? props.padding : '0'};
  .formError {
    border-width: 0 0 2px;
    border-color: #ce0a24;
    color: #ce0a24;
    outline: 0;
    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #ce0a24;
      opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #ce0a24;
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #ce0a24;
    }
  }
  li {
    font-family: Cormorant;
    font-size: 12px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 160%;
    letter-spacing: 0.0456169px;
    color: ${props => props.theme.colors.black};

    &:first-child {
      margin-top: 1em;
      margin-bottom: 0.4em;
      font-family: Cormorant;
      font-size: 22px;
      font-weight: 500;
      font-stretch: normal;
      line-height: 26px;
      letter-spacing: 0.0727273px;
    }
  }
  a {
    vertical-align: middle;
    text-decoration: none;
    font-family: Raleway;
    font-size: 12px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 160%;
    letter-spacing: 0.0456169px;
    color: ${props => props.theme.colors.black};
    &:hover {
      color: ${props => props.theme.colors.tertiary};
    }
  }
  input {
    margin-top: 1.3em;
    width: 100%;
    -ms-box-sizing: border-box; /* ie8 */
    -khtml-box-sizing: border-box; /* konqueror */
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box; /* Opera/IE 8+ */
    box-sizing: border-box; /* css3 rec */
    border-width: 0 0 1.5px;
    border-color: ${props => props.theme.colors.black};
    vertical-align: middle;
    padding: 10px 10px 10px 0;
    background-color: ${props => props.theme.colors.tan};
    font-family: Raleway;
    font-size: 16px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.0456169px;
    color: ${props => props.theme.colors.black};
    outline: 0;
    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: ${props => props.theme.colors.black};
      opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: ${props => props.theme.colors.black};
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: ${props => props.theme.colors.black};
    }
  }

  button {

    background-color: ${props => props.theme.colors.tertiary};
    text-align: center;

    font-family: Raleway;
    font-size: 16px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 140%;
    letter-spacing: 0.0456169px;
    text-align: center;

    text-decoration: none;
    color: white;

    transition: all 0.2s;

    border: none;
    border-radius: 4.54299px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);

    padding: ${props => props.padding ? props.padding : '0 20px'};

    &:hover {
      text-decoration: none;
      box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
    }

    margin-top: 1.2em;
    padding: 10px;
  }
  @media screen and (max-width: 630px) {
    button {
      padding: 13px 28px;
    }
    .button-content {
      width: 100%;
      text-align: center;
    }
  }
`
const activeLinkStyle = {
  color: theme.colors.tertiary,
}

const Row = props => (
  <Flex
    {...props}
    flexWrap="wrap"
    mx={-3}
    css={{
      maxWidth: '573px',
      margin: '0 auto',
    }}
  />
)

const FormMessage = styled.div`
  font-family: Cormorant;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.44;
  letter-spacing: 0.1px;
  text-align: center;
  color: ${props => props.theme.colors.black};

  & a {
    text-decoration: underline;
  }
`

const SuscribeTitle = styled.div`
  margin-top: 2em;
  font-family: Cormorant;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  font-size: 22px;
  text-align: left;
  letter-spacing: 0.0727273px;
  color: ${props => props.theme.colors.black};
`
const Copyright = styled.div`
  margin-top: 0.8em;
  font-family: Raleway;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.79;
  letter-spacing: 0.1px;
  color: ${props => props.theme.colors.darkGrey};

  @media screen and (max-width: 630px) {
    margin-top: 1.8em;
    text-align: center;
  }
`

export default class Footer extends React.Component {
  state = {
    name: null,
    email: null,
    errors: false,
    messages: false,
  }
  errors = {}

  _handleChange = e => {
    const element = this.refs.element
    element.classList.remove('formError')

    this.setState({
      [`${e.target.name}`]: e.target.value,
      errors: false,
      messages: false,
    })
  }

  _handleSubmit = e => {
    e.preventDefault()

    let data = {
      // EMAIL: this.state.EMAIL,
      EMAIL: this.state.email
    }
    const mailChimpEndpoint = 'https://embello.us18.list-manage.com/subscribe/post?u=0eedca2625c79d77ed669f13d&amp;id=3a83eb91c4'

    // addToMailchimp(this.state.email, { name: this.state.name })
    addToMailchimp(this.state.email, data, mailChimpEndpoint)
      .then(({ msg, result }) => {
        const element = this.refs.element
        element.classList.remove('formError')

        if (result !== 'success') {
          throw msg
        }

        this.setState({
          errors: false,
          messages: msg,
        })
      })
      .catch(err => {
        const element = this.refs.element
        element.classList.add('formError')

        this.setState({
          errors: true,
          messages: err,
        })
      })
  }

  render() {
    const errors = this.state.errors
    const messages = this.state.messages

    return (
      <Wrapper>
        <Row>
          <Column width={[1 / 2, 1 / 2, 1 / 4]}>
            <ul>
              <li>Contact</li>
              <li>
                <a href="mailto:hello@embello.com" target="_blank" rel="noopener noreferrer">
                  hello@embello.com
                </a>
              </li>
              {/* <li>
                <a href="tel:(310) 694-0446">(310) 694-0446</a>
              </li>
              <li>
                <a href="https://calendly.com/embello/brand-consultation?back=1&month=2020-10" target="_blank" rel="noopener noreferrer">Schedule a Call</a>
              </li> */}
            </ul>
          </Column>
          <Column width={[1 / 2, 1 / 2, 1 / 4]}>
            <ul>
              <li>Info</li>
              <li>
                <Link to="/about/">About Us</Link>
              </li>
              <li>
                <Link to="/press/">Press</Link>
              </li>
              <li>
                <Link to="/partnerships/">Partnerships</Link>
              </li>
               <li>
                <a href = "https://join.embello.com/influencer-faq/" target = "_blank" >Influencer FAQ</a>
              </li>
              <li>
                <Link to="/blog/">Blog</Link>
              </li>
              {/* <li>
                <a href="https://go.oncehub.com/Embello" target="_blank">
                  Pricing
                </a>
              </li> */}
            </ul>
          </Column>
          <Column width={[1 / 2, 1 / 2, 1 / 4]}>
            <ul>
              <li>Legal</li>
              <li>
                <Link to="/privacypolicy/">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/termsofuse/">Terms of Use</Link>
              </li>
            </ul>
          </Column>

          <Column width={[1 / 2, 1 / 2, 1 / 4]}>
            <ul>
              <li>Stay In Touch</li>
              <li>
                <a
                  href="https://www.instagram.com/embello_inc/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Instagram
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/meetembello/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Facebook
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/embello-inc/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  LinkedIn
                </a>
              </li>
            </ul>
          </Column>
        </Row>
        <Row>
          <Column width={1}>
            <SuscribeTitle>
              Subscribe to our newsletter for industry tips and resources.
            </SuscribeTitle>
          </Column>
        </Row>
        <form onSubmit={this._handleSubmit}>
          <Row>
            <Column width={[1, 2 / 3, 3 / 4]} padding="0 22px 0 0">
              <input
                ref="inputEmail"
                ref="element"
                type="email"
                id="email"
                onChange={this._handleChange}
                placeholder="Email address"
                name="email"
              />
            </Column>
            <Column width={[1, 1 / 3, 1 / 4]}>
              <div className="button-content">
                <button type="submit">SUBMIT</button>
              </div>
            </Column>
          </Row>
          <Row>
            <Column width={[1, 2 / 3, 3 / 4]}>
              {errors ? (
                <FormMessage className="formError" dangerouslySetInnerHTML={{__html: (messages || '') }} />
              ) : (
                <FormMessage dangerouslySetInnerHTML={{__html: (messages || '') }} />
              )}
            </Column>
          </Row>
        </form>
        <Row>
          <Column width={1}>
            <Copyright>Copyright © 2019 Embello Inc.</Copyright>
          </Column>
        </Row>
      </Wrapper>
    )
  }
}
