module.exports = {
  siteTitle: 'EMBELLO',
  siteTitleAlt: 'CONNECT, CREATE, INFLUENCE', // This allows an alternative site title for SEO schema.
  publisher: 'EMBELLO', // Organization name used for SEO schema
  siteDescription:
    'Embello is the only marketing platform dedicated to the home and design industry. Our workflow tools, analytics and platform/agency hybrid model allows you to oversee your marketing strategy, while leaving the heavy lifting to us.',
  siteUrl: 'https://www.embello.com', // Site domain. Do not include a trailing slash! If you wish to use a path prefix you can read more about that here: https://www.gatsbyjs.org/docs/path-prefix/
  postsPerHomePage: 6, // Number of posts shown on the 1st page of of the index.js template (home page)
  postsPerPage: 6, // Number of posts shown on paginated pages
  postsPerBlogPage: 3,
  pressPostPerPressPage: 4,
  author: 'EMBELLO', // Author for RSS author segment and SEO schema
  authorUrl: 'https://www.embello.com/about/', // URL used for author and publisher schema, can be a social profile or other personal site
  userTwitter: '@twitter', // Change for Twitter Cards
  shortTitle: 'EMBELLO', // Used for App manifest e.g. Mobile Home Screen
  shareImage: '/logos/share.jpg', // Open Graph Default Share Image. 1200x1200 is recommended
  shareImageWidth: 900, // Change to the width of your default share image
  shareImageHeight: 600, // Change to the height of your default share image
  siteLogo: '/android-chrome-384x384.png', // Logo used for SEO, RSS, and App manifest
  backgroundColor: '#e9e9e9', // Used for Offline Manifest
  themeColor: '#121212', // Used for Offline Manifest
  copyright: 'Copyright © 2018 EMBELLO', // Copyright string for the RSS feed
}
