const theme = {
  colors: {
    base: '#E8D0BC',
    secondary: '#090C30',
    tertiary: '#B56522',
    tertiaryTwo: '#B66528',
    rose: '#D8B9B8',
    highlight: '#BCB5AB',
    white: '#FFFFFF',
    black: '#000000',

    pink: '#ECDDDC',
    transparent: 'transparent',
    green: '#BFC7BE',
    tan: '#EAE4DB',
    grey: '#EBE9E6',
    darkGrey: '#989898',
    greenOverlay: 'rgba(221, 226, 232, 0.8)',
    lightGrey: '#C4C4C4'
  },
  sizes: {
    maxWidth: '1170px',
    maxWidthCentered: '1033px',
    maxNavWidth: '1440px'
  },
  breakpoint: {
    small: 420,
    smallMedium: 620,
    medium: 1000,
    large: 1024,
    extraLarge: 1200
  },
  responsive: {
    small: '35em',
    medium: '50em',
    large: '70em',
  },
}

module.exports = theme
