import React from 'react'
import styled from 'styled-components'

class NavDropdown extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      displayMenu: false,
      // For carrot animation
      afterRender: false
    }
    this.showDropdownMenu = this.showDropdownMenu.bind(this);
    this.hideDropdownMenu = this.hideDropdownMenu.bind(this);

    this.dropdownRef = React.createRef();
  }

  componentDidMount() {
    // const url = typeof window !== 'undefined' ? window.location.pathname : '';
  }

  showDropdownMenu(event) {
    event.preventDefault();
    this.setState({ displayMenu: true, afterRender: true }, () => {
      document.addEventListener('click', this.hideDropdownMenu);
    });
  }

  hideDropdownMenu() {
    this.setState({ displayMenu: false }, () => {
      document.removeEventListener('click', this.hideDropdownMenu);
    });
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.hideDropdownMenu);
  }

  getCarrotClass() {
    let className = '';

    if (this.state.afterRender) className = 'fade-out-carrot';
    if (this.state.displayMenu) className = 'fade-in-carrot';

    return className;
  }

  render() {
  
    return (
      <>
        <DropdownButton onClick={this.showDropdownMenu}>
          {this.props.buttonLabel}
          {this.props.hasCarrot && 
            <div className={this.getCarrotClass()}>
              <svg width="20.020201" height="11.424314" viewBox="0 0 20.020201 11.424314" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="m 19.0101,10.414214 -9,-9 -9,9" strokeWidth="2" strokeLinecap="round"/>
              </svg>
            </div>
          }
        </DropdownButton>
        
        <DropdownContent className={`fadeIn ${this.state.displayMenu && 'show-dropdown'}`} ref={this.dropdownRef}>
          {this.props.children}
        </DropdownContent>
      </>
    )
  }
}

const DropdownButton = styled.button`
  user-select: none;
  background: none;
	border: none;
	padding: 0;
	cursor: pointer;
	outline: inherit;
  
  display: flex;
  align-items: center;

  vertical-align: middle;
  text-decoration: none;
  color: ${props => props.theme.colors.black};
  font-family: Raleway;
  font-style: normal;
  font-weight: 300;
  line-height: 27px;
  font-size: 16px;
  text-align: center;
  letter-spacing: 0.0456169px;
  transition: all 0.2s;

  height: 100%;

  &:hover {
    text-shadow: .25px 0px .1px, -.25px 0px .1px;
  }

  &:hover svg path {
    stroke: ${props => props.theme.colors.tertiary};
  }

  svg path {
    stroke: ${props => props.theme.colors.secondary};
  }

  .fade-in-carrot svg path {
    stroke: ${props => props.theme.colors.tertiary};
  }

  svg {
    margin-left: 0.8em;
    margin-bottom: 0px;
    transform: rotate(180deg);
  }

  .fade-in-carrot svg {
    transform: rotate(0deg);
    animation: fade-in-carrot-keyframes 0.3s;
  }

  .fade-out-carrot svg {
    transform: rotate(180deg);
    animation: fade-out-carrot-keyframes 0.3s;
  }

  @keyframes fade-out-carrot-keyframes {
    from {
		  transform: rotate(0deg);
    }
    to {
      transform: rotate(180deg);
    }
  }

  @keyframes fade-in-carrot-keyframes {
    from {
		  transform: rotate(180deg);
    }
    to {
      transform: rotate(0deg);
    }
  }
`;

const DropdownContent = styled.ul`
  &.fadeIn {
    width: auto;
    height: auto;
    overflow: auto;
    transition: none;

    position: absolute;
    display: none;
    list-style-type: none;
    padding: 0;
    min-width: 225px;
    top: 55px;
    background: rgba(255, 255, 255, 0.9);
    
    /* Main Shadow */
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    z-index: 3;

    animation: fade-in-keyframes 0.3s;

    left: calc(50% - 112px);
  }

  &.show-dropdown {
    display: block;
  }

  @keyframes fade-in-keyframes {
    from {
      opacity: 0;
		  transform: translate3d(0, -20%, 0);
    }
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  & a.dropdown-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    text-align: center;

    color: ${props => props.theme.colors.black};
  }

  & li {
    padding: 10px 25px;
    display: block;
    min-width: 225px;
    align-items: center;
  }

  @media only screen and (max-width: 1100px) {
    &.fadeIn {
      position: relative;
      top: 0;
      box-shadow: none;
      display: block;
      z-index: auto;
      border-radius: 0;
      left: 0;
    }

    & li {
      align-items: flex-end;
    }
  }
  `;

export default NavDropdown
