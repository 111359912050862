import React, { Fragment } from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Helmet from 'react-helmet'
import InstagramLogin from 'react-instagram-login'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import axios from 'axios'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import PubSub from 'pubsub-js'
import Button from './Button'
import NavDropdown from './NavDropdown'

const FormMessage = styled.div`
  font-family: Cormorant;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.44;
  letter-spacing: 0.1px;
  text-align: center;
  color: ${props => props.theme.colors.black};
  padding-top: ${props => props.paddingTop || '0'};
`

const FormDiv = styled.div`
  margin: 0 auto;
  width: 100%;
  margin-bottom: 3em;
  font-family: Raleway;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.69;
  letter-spacing: normal;
  text-align: center;
  color: ${props => props.theme.colors.black};
`

const RowForm = styled.div`
  padding-top: ${props => props.paddingTop || '0'};
  margin: 0 auto;
  max-width: 379px;
  .formError {
    border-color: #ce0a24;
    color: #ce0a24;
    outline: 0;
    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #ce0a24;
      opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #ce0a24;
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #ce0a24;
    }
  }

  input {
    padding: 6px 17px;
    width: 100%;
    border: 1.2775px solid ${props => props.theme.colors.lightGrey};
    border-radius: 4px;
    box-sizing: border-box;
    font-family: Raleway;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 18px;
    letter-spacing: 0.141538px;
    color: ${props => props.theme.colors.black};
    outline: 0;
    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: ${props => props.theme.colors.lightGrey};
      opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: ${props => props.theme.colors.black};
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: ${props => props.theme.colors.black};
    }
  }

  button {
    /* margin-top: 1em;
    padding: 1em;
    width: 100%;
    background-color: ${props => props.theme.colors.black};
    color: white;
    font-family: Raleway;
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    &:hover {
      text-decoration: none;
      background-color: ${props => props.theme.colors.tertiary};
    }
    &.active {
      text-decoration: none;
      background-color: ${props => props.theme.colors.tertiary};
    } */
  }

  & a {
    text-decoration: none;
  }

  & div.mce_inline_error {
    display: inline-block;
    margin: 2px 0 0em 0 !important;
    padding: 5px 10px !important;
    background-color: rgba(255,255,255,0.85) !important;
    -webkit-border-radius: 3px !important;
    -moz-border-radius: 3px !important;
    border-radius: 3px !important;
    font-size: 14px !important;
    font-weight: normal !important;
    z-index: 1 !important;
    color: #ce0a24 !important;

    font-family: Cormorant;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.44;
    letter-spacing: 0.1px;
    text-align: center;
    padding-top: ${props => props.paddingTop || '0'};
  }

  & input.mce_inline_error {
    border-color: #ce0a24 !important;
  }
`

const RowButtom = styled.div`
  margin: 0 auto;
  max-width: 379px;
`

const ColumnButton = styled.div`
  display: inline-flex;
  width: 50%;

  &:last-child {
    padding-left: 0.5em;
  }  
  &:first-child {
    padding-right: 0.5em;
  }  
  & a.button-content {
    text-decoration: none;
    min-width: 100%;
  }
`

const Label = styled.div`
  padding: 1.8em 0 0.5em 0;
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  font-stretch: normal;
  letter-spacing: 0.0456169px;
  text-align: left;
  width: 100%;
  color: ${props => props.theme.colors.lightGrey};
  margin: 0 auto;
  max-width: 379px;

  & .asterisk {
    color: #ce0a24;
    opacity: 0.7;
  }
`


const CloseModal = styled.div`
  cursor: pointer;
  width: 70px;
  @media only screen and (max-width: ${props => props.theme.breakpoint.smallMedium}px) {
    width: 100%;
    padding-bottom: 40px;
  }
  & img {
    float: right;
  }
`
const ModalLogo = styled.div`
  vertical-align: middle;
  float: left;
`

const Modal = styled.div`
  overflow: auto;
  .instagram-button {
    margin: 0 auto;
    width: 100%;
    cursor: pointer;
    max-width: 338px;
    padding: 1em;
    margin-top: 1em;
    background: rgba(181, 101, 34, 0.3);
    font-family: Raleway;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.1px;
    text-align: center;
    color: ${props => props.theme.colors.black};
  }

  .facebook-button {
    margin: 0 auto;
    width: 100%;
    cursor: pointer;
    max-width: 338px;
    padding: 1em;
    margin-top: 1em;
    font-family: Raleway;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.1px;
    text-align: center;
    background-color: ${props => props.theme.colors.black};
    color: ${props => props.theme.colors.white};
  }

  /* background: ${props =>
    props.type === 'register'
      ? props.theme.colors.white
      : props.theme.colors.base}; */
  text-align: center;
  /* position: fixed;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  
  margin: 0 auto;
  z-index: 99;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  transition: 0.2s all;
  a {
    color: ${props => props.theme.colors.black};
  }
  opacity: ${props => (props.visible ? '1' : '0')};
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};

  p {
    line-height: 1.6;
    margin: 0 0 2em 0;
  }

  background: rgba(0, 0, 0, 0.71);
`

const ModalContainer = styled.div`
  overflow: auto;
  background: ${props => props.theme.colors.white};
  padding: 40px 40px 50px 110px;
  // position: relative;
  max-width: 100%;

  @media only screen and (max-width: ${props => props.theme.breakpoint.smallMedium}px) {
    width: 100%;
    max-width: 100%;
    min-height: 100%;
    padding: 35px 24px 35px 24px;
  }
`

const ModalNavContainer = styled.div`
  max-width: ${props => props.theme.sizes.maxWidth};
  width: 100%;
  min-height: 40px;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: row;
  max-width: 508px;

  .block {
    flex-direction: column-reverse;
    max-width: 379px;
  }

  @media only screen and (max-width: ${props => props.theme.breakpoint.smallMedium}px) {
    flex-direction: column-reverse;
    max-width: 379px;
  }
`

const ModalBodyContainer = styled.div`
  padding-right: 70px;
  @media only screen and (max-width: ${props => props.theme.breakpoint.smallMedium}px) {
    padding: 0;
  }
`

const Header = styled.header`
  width: 100%;
  height: 109px;
  padding: 2em;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;

  &.nav-transparent {
    background: none;
  }

  &.nav-white {
    background: ${props => props.theme.colors.white};
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  }

  #menu-icon {
    display: hidden;
    width: 59px;
    height: 52.06px;

    background: url(/icons/Hambuger_2.svg) center;
  }

  a:hover#menu-icon {
    background-color: #444;
    border-radius: 4px 4px 0 0;
  }
  ul {
    vertical-align: middle;
    list-style: none;
  }

  li {
    display: inline-block;
    float: left;
  }

  @media only screen and (min-width: 1100px) {
    &.nav-transparent.nav-lg-white {
      background: ${props => props.theme.colors.white};
      box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    }
  }

  /*MEDIA QUERY*/
  @media only screen and (max-width: 1100px) {
    padding: 1.8em 1.5em 1.8em 1.9em;
    
    header {
      position: absolute;
    }

    #menu-icon {
      // display: inline-block;
    }

    nav ul.main-nav,
    nav:active ul.main-nav {
      vertical-align: middle;
      display: 'block';
      opacity: ${({ open }) => open ? '1' : '0'};
      transform: ${({ open }) => open ? 'translateX(0%)' : 'translateX(100%)'};
      position: absolute;
      padding: 80px 20px 20px;
      background: ${props => props.theme.colors.white};
      width: 100%;
      height: 100vh;
      top: 0;
      left: 0;
      transition: transform 0.3s ease-in-out, opacity 0.3s ease 0s;
      overflow: scroll;
    }

    nav li {
      vertical-align: middle;
      text-align: center;
      float: right;
      width: 60%;
      padding: 35px 0;
      margin: 0;
      border-bottom: 1px solid ${props => props.theme.colors.grey}; 
      margin-right: 10.4px !important;
      &:last-child {
        margin-left: 0;
      }
    }

    .dropdown-button-content {
      float: right;
      width: 60%;
      border-bottom: 1px solid ${props => props.theme.colors.grey}; 
    }

    nav li.dropdown-content {
      height: auto;
      width: auto;
      padding: 0;
      border-bottom: none;
      margin-right: 0 !important;
    }

    nav li.dropdown-content a {
      display: block;
    }

    nav li a {
      text-align: right !important;
      font-family: Raleway;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      font-size: 14px;
    }

    nav li .signup-button {
      background-color: transparent;
      color: ${props => props.theme.colors.tertiary};
      border: none !important;
      padding: 0 !important;
      justify-content: flex-end !important;

      text-align: right !important;
      font-family: Raleway;
      font-style: normal;
      font-weight: bold;
      line-height: normal;
      font-size: 20px;
      box-shadow: none;
      float: right;
      min-height: auto;
    }

    nav li.dropdown-content button {
      display: none;
    }

    /* nav:hover ul {
      display: block;
    } */
  }
`

const Logo = styled.div`
  vertical-align: middle;
  position: absolute;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  width: 234px;
  z-index: 10;

  a img {
    position: relative;
    top: 50%;
    /* transform: perspective(1px) translateY(-50%); */
  }

  /*MEDIA QUERY*/
  @media only screen and (max-width: 1170px) {
    width: 152px;
  }
`

const NavContainer = styled.div`
  max-width: ${props => props.theme.sizes.maxNavWidth};
  margin: 0 auto;
`

const Nav = styled.nav`
  float: right;

  ul {
    list-style: none;
    justify-content: space-between;
    vertical-align: middle;
  }

  & ul li a {
    display: inline-block;
    vertical-align: middle;
  }

  li {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    float: left;
    vertical-align: middle;
    cursor: pointer;
    height: 45px;
  }
  

  li:not(:last-child) {
    margin-right: 2em;
  }
  
  .dropdown-content li {
    margin-right: 0;
    min-height: 65px;
  }

  .dropdown-content li:not(:last-child) {
    /* border-bottom: 1px solid ${props => props.theme.colors.grey}; */
  }

  a {
    vertical-align: middle;
    text-decoration: none;
    color: ${props => props.theme.colors.black};
    font-family: Raleway;
    font-style: normal;
    font-weight: 300;
    line-height: 27px;
    font-size: 16px;
    text-align: center;
    letter-spacing: 0.0456169px;
    transition: all 0.2s;

    &:hover {
      // color: ${props => props.theme.colors.tertiary};
      // font-weight: bold;
      text-shadow: .25px 0px .1px, -.25px 0px .1px;
    }
  }
  
`

const SignButton = styled.div`
  background-color: ${props => props.theme.colors.black};
  min-width: 119px;
  height: 45px;
  text-align: center;

  font-family: Raleway;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  text-align: center;

  text-decoration: none;
  color: white;

  transition: all 0.2s;
  display: flex;
  justify-content: center;
  flex-direction: column;
  &:hover {
    text-decoration: none;

    background-color: ${props => props.theme.colors.tertiary};
  }
`

const ProfileButton = styled.div`
  display: -webkit-box;
  text-align: center;
  line-height: 26px;
  span {
    margin-left: 0.3em;
    vertical-align: middle;
  }
`

const LoginButton = styled.div`
  min-width: 119px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-family: Raleway;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  text-align: center;

  height: 45px;
  border: solid 1.5px ${props => props.theme.colors.black};
  text-align: center;
  &:hover {
    border: solid 1.5px ${props => props.theme.colors.tertiary};
    color: ${props => props.theme.colors.tertiary};
    a {
      color: ${props => props.theme.colors.tertiary};
    }
  }
`
const ModalTitleConfirm = styled.div`
  margin: 0 auto;
  margin-top: 2em;
  font-family: Raleway;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  font-size: 28px;
  text-align: center;
  letter-spacing: 0.27px;
  color: ${props => props.theme.colors.black};
`

const ModalSubTitleConfirm = styled.div`
  margin: 0 auto;
  margin-top: 1em;
  max-width: 493px;
  font-family: Cormorant;
  font-style: normal;
  font-weight: normal;
  line-height: 30px;
  font-size: 18px;
  text-align: center;
  letter-spacing: 0.28px;
  color: ${props => props.theme.colors.black};
`

const ModalSubTitleConfirm2 = styled.div`
  margin: 0 auto;
  margin-top: 1em;
  max-width: 493px;
  font-family: Cormorant;
  font-style: normal;
  font-weight: bold;
  line-height: 30px;
  font-size: 18px;
  text-align: center;
  letter-spacing: 0.28px;
  text-align: center;
  color: ${props => props.theme.colors.black};
`

const ModalTitle = styled.div`
  width: 100%;
  max-width: 493px;
  margin: 0 auto;
  font-family: Cormorant;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  font-size: 24px;
  text-align: ${props => props.align || 'left'};
  color: ${props => props.theme.colors.black};
  padding-top: 7px;
  text-align: center;
  @media only screen and (max-width: ${props => props.theme.breakpoint.smallMedium}px) {
    padding-top: 0px;
  }
`

const ModalImage = styled.div`
  padding: 79px 0 30px;
  text-align: center;
  width: 100%;
`

const ModalSubTitle = styled.div`
  margin: 0 auto;
  max-width: 493px;
  padding: 0.8em 0 0;
  font-family: Raleway;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 28px;
  text-align: ${props => props.align || 'center'};
  letter-spacing: 0.6px;
  color: ${props => props.theme.colors.black};
  max-width: 379px;

  &.questionnaire {
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.0456169px;
  }
`

const ModalSubTitle2 = styled.div`
  margin: 0 auto;
  max-width: 493px;
  font-family: Cormorant;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.6px;
  text-align: center;
  color: ${props => props.theme.colors.black};
`
const ModalSubTitle3 = styled.div`
letter-spacing: 0.114583px;
  margin: 0 auto;
  max-width: 493px;
  margin-bottom: 1em;
  font-family: Raleway;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.69;
  letter-spacing: normal;
  text-align: center;

  color: ${props => props.theme.colors.black};
`

const ModalInstagramLink = styled.div`
  margin: 0 auto;
  width: 100%;
  cursor: pointer;
  max-width: 338px;
  padding: 1em;
  margin-top: 1em;
  background: rgba(181, 101, 34, 0.3);
  font-family: Raleway;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.1px;
  text-align: center;
  color: ${props => props.theme.colors.black};
`

const ModalFacebookLink = styled(ModalInstagramLink)`
  background-color: ${props => props.theme.colors.black};
  color: ${props => props.theme.colors.white};
`
const ModalPinterestLink = styled(ModalInstagramLink)`
  background-color: rgba(216, 185, 184, 0.7);
`

const ModalLoginLink = styled.div`
  margin: 0 auto;
  margin-top: 1.5em;
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  line-height: 32px;
  font-size: 22px;
  text-align: center;
  letter-spacing: 0.33px;
  color: ${props => props.theme.colors.black};
  a {
    text-decoration: none;
    cursor: pointer;
  }
`

const ModalFooterConfirm = styled.div`
  font-family: Cormorant;
  margin: 0 auto;
  margin-top: 1em;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.38px;
  text-align: left;
  color: ${props => props.theme.colors.black};
`

const ModalFooter = styled.div`
  max-width: 379px;
  margin: 0 auto;
  font-family: Raleway;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;
  font-size: 16px;
  text-align: center;
  letter-spacing: 0.2px;
  color: ${props => props.theme.colors.black};
  padding-top: ${props => props.paddingTop || '0'};
`

const activeLinkStyle = {
  // color: theme.colors.tertiary,
  fontWeight: 'bold'
}

const BurgerButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 30.4px;
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 31.24px;
  height: 33px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 12;

  &:focus {
    outline: none;
  }

  & div {
    width: 31.24px;
    height: 2.7px;
    background: ${({ open }) => open ? '#0D0C1D' : '#B56522'};
    border-radius: 2px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ open }) => open ? 'rotate(49deg)' : 'rotate(0)'};
    }

    :nth-child(2) {
      opacity: ${({ open }) => open ? '0' : '1'};
      transform: ${({ open }) => open ? 'translateX(20px)' : 'translateX(0)'};
    }

    :nth-child(3) {
      transform: ${({ open }) => open ? 'rotate(-49deg)' : 'rotate(0)'};
    }
  }


  @media only screen and (max-width: 1100px) {
    display: flex;
    & div { 
      display: hidden;
    }
  }
`

const NavLink = ({ navItem, ...rest }) => {

  if (navItem.isExternalLink){
    return <a href={navItem.action} target="_blank" rel="noopener noreferrer" {...rest}>{navItem.title}</a>
  } else if (navItem.action && !navItem.isExternalLink){
    return(
      <Link to={navItem.action} activeStyle={activeLinkStyle} {...rest}>
        {navItem.title}
      </Link>
    )
  } 
  return <a href={navItem.action} rel="noopener noreferrer" {...rest}>{navItem.title}</a>
}

class PureMenu extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      phone: '',
      ighandle: '',
      image: '',
      email: '',
      message: '',
      showLoginModal: false,
      showRegisterModal: false,
      showInfluencerRegisterModal: false,
      showInfluencerLoginModal: false,
      isAuthenticated: false,
      isTop: true,
      menuIsOpen: false,
      typeLogin: 'BRAND',
      // New mailchimp values
      EMAIL: '',
      FNAME: '',
      LNAME: '',
      MMERGE3: '',
      mailchimpListId: 'ebb1778a57'

    }
    this.emailInputRef = React.createRef();
  }

  componentWillMount() {
    // This is where we subscribe this class to the 'GET FILES' subscription.
    // once a publish event for 'GET FILES' has been fired, FileList.subscriber() will be triggered.
    this.token = PubSub.subscribe('openDialog', this.subscriber.bind(this))
  }

  componentDidMount() {
    this._isMounted = true;
    this.setState({ menuIsOpen: false });
    document.addEventListener('scroll', () => {
      const isTop = window.scrollY < 100;
      if (isTop !== this.state.isTop) {
        if (this._isMounted) {
          this.setState({ isTop })
        }
      }
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
    PubSub.unsubscribe(this.token)
  }

  subscriber(msg, data) {
    var self = this
    this.openInfluencerRegisterModal()
  }

  responseInstagram = response => {
    axios
      .post('http://localhost:8080/auth/instagram_signup', {
        code: response,
      })
      .then(res => {
        const userInfo = res.data[0]
        this.closeModal()
        this.setState({
          isAuthenticated: true,
          name: userInfo.me.name,
          image: userInfo.me.instagram_pic,
        })
      })
      .catch(function(error) {})
  }

  responseFacebook = response => {
    /**
     * The instagram login also sends a code, so it triggers
     * this facebook callback even when we are logging in with
     * instagram. This is a little hack to preven that
     */
    const params = window.location.search
    if (params.indexOf('code') >= 0) {
      return
    }

    axios
      .post('http://localhost:8080/auth/facebook_signup', {
        name: response.name,
        email: response.email,
        facebook_id: response.id,
        facebook_token: response.accessToken,
        facebook_pic:
          response.picture && response.picture.data
            ? response.picture.data.url
            : '',
      })
      .then(res => {
        const userInfo = res.data[0]
        this.closeModal()
        this.setState({
          isAuthenticated: true,
          name: userInfo.me.name,
          image: userInfo.me.facebook_pic,
        })
      })
      .catch(err => {
        console.log(err)
      })
  }

  closeModal = () => {
    this.setState({
      showLoginModal: false,
      showRegisterModal: false,
      showInfluencerRegisterModal: false,
      showInfluencerLoginModal: false,
    })
  }
  openLoginModal = () => {
    this.setState({
      showLoginModal: true,
      showRegisterModal: false,
      showInfluencerRegisterModal: false,
      showInfluencerLoginModal: false,
    })
  }

  openRegisterModal = () => {
    window.location = 'signup/'
  }

  openInfluencerRegisterModal = () => {
    this.setState({
      showInfluencerRegisterModal: true,
      showLoginModal: false,
      showRegisterModal: false,
      showInfluencerLoginModal: false,
      errors: false,
      messages: '',
    })
  }

  openInfluencerLoginModal = () => {
    this.setState({
      showInfluencerLoginModal: true,
      showLoginModal: false,
      showRegisterModal: false,
      showInfluencerRegisterModal: false,
    })
  }

  state = {
    name: null,
    phone: null,
    ighandle: null,
    email: null,
    errors: false,
    messages: false,
  }
  errors = {}

  _handleChange = e => {
    const element = this.refs.element
    const arrayEmailInputs = document.querySelectorAll('[name=email]')
    //element.classList.remove('formError')

    if (arrayEmailInputs && arrayEmailInputs.length) {
      arrayEmailInputs.forEach(inputEmail => {
        // inputEmail.classList.remove('formError')
      });
    }

    this.setState({
      [`${e.target.name}`]: e.target.value,
      errors: false,
      messages: false,
    })
  }

  _handleSubmit = e => {
    e.preventDefault()
    
    if (this.state.typeLogin !== 'INFLUENCER' &&
    this.state.typeLogin !== 'BRAND') {
      this.setState({
        errors: true,
        messages: 'Please select if you are an influencer or a brand.',
      })
      return;
    } else {
      this.setState({
        errors: false,
        messages: '',
      })
    }
    
    let data = {
      // EMAIL: this.state.EMAIL,
      FNAME: this.state.FNAME,
      LNAME: this.state.LNAME
    }
    let mailChimpEndpoint = 'https://embello.us18.list-manage.com/subscribe/post?u=0eedca2625c79d77ed669f13d&amp;id=ebb1778a57'
    
    if (this.state.typeLogin === 'BRAND') data['MMERGE3'] = this.state['MMERGE3']
    else {
      data['MMERGE5'] = this.state['MMERGE5']
      mailChimpEndpoint = 'https://embello.us18.list-manage.com/subscribe/post?u=0eedca2625c79d77ed669f13d&amp;id=b7ce4943d7'
    }
    
    const arrayEmailInputs = document.querySelectorAll('[name=email]')
    addToMailchimp(this.state.EMAIL, data, mailChimpEndpoint)
      .then(({ msg, result }) => {
        if (arrayEmailInputs && arrayEmailInputs.length) {
          arrayEmailInputs.forEach(inputEmail => {
            inputEmail.classList.remove('formError')
          });
        }
        const element = this.refs.element
        // element.classList.remove('formError')

        if (result !== 'success') {
          throw msg
        }

        this.setState({
          errors: false,
          messages: msg,
        })
        this.openLoginModal()
      })
      .catch(err => {
        if (arrayEmailInputs && arrayEmailInputs.length) {
          arrayEmailInputs.forEach(inputEmail => {
            inputEmail.classList.add('formError')
          });
        }
        const element = this.refs.element
        // element.classList.add('formError')

        this.setState({
          errors: true,
          messages: err,
        })
      })
  }

  setOpen = (e, open) => {
    e.preventDefault()
    this.setState({ menuIsOpen: open })
  }

  changeTypeLogin = (type) => {
    this.setState({ typeLogin: type, mailchimpListId: type === 'BRAND' ? 'ebb1778a57' : 'b7ce4943d7' });
  }

  malChimpForm() {
    return {
      __html: `<script type='text/javascript'>(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]='EMAIL';ftypes[0]='email';fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text';fnames[3]='MMERGE3';ftypes[3]='url';fnames[5]='MMERGE5';ftypes[5]='text';}(jQuery));var $mcj = jQuery.noConflict(true);</script>`
    }
  }

  renderDropdown(navItem) {
    if (navItem.elements && Array.isArray(navItem.elements)){
      return(
        <NavDropdown buttonLabel={navItem.title}>
          {navItem.elements.map((childItem, index) =>
            <li key={`nav-subitem-${index}`}>
              <NavLink navItem={childItem} className="dropdown-item"/>
            </li>
          )}
        </NavDropdown>
      )
    }
    return <a href={navItem.action}>{navItem.title}</a>
  }

  renderNavItem() {
    const navItems = this.props.data.contentfulMainMenu ? this.props.data.contentfulMainMenu.elements : [];
    
    const items = navItems.map((navItem, index) => { 

      const link = navItem.isParent ? this.renderDropdown(navItem) : <NavLink navItem={navItem}/>

      return (
        <li key={`nav-item-${index}`} className={navItem.isParent ? 'dropdown-content' : ''}>
          {link}
        </li>
      )
    })
    return items
  }

  render() {
    const errors = this.state.errors
    const messages = this.state.messages
    const isTop = this.state.isTop
    const menuIsOpen = this.state.menuIsOpen 
    let className = '';


    if (isTop && !this.props.whiteNav) className += ' nav-transparent' 
    else className += 'nav-white'

    if (this.props.whiteLgNav) className += ' nav-lg-white'

    return (
      <>
      <Helmet>
        <script type='text/javascript' src='//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js' />
      </Helmet>
      <Header 
        className={className}
        open={menuIsOpen}
      >
        <NavContainer>
          <Logo>
            <Link to="/" className="logo" activeStyle={activeLinkStyle}>
              <img src="/logos/EmbelloLogo_NoTagline_Black.svg" alt="EMBELLO" />
            </Link>
          </Logo>
          <BurgerButton open={menuIsOpen} onClick={(e) => this.setOpen(e, !menuIsOpen)}>
            <div />
            <div />
            <div />
          </BurgerButton>

          <Nav>
            <ul className="main-nav">
              {this.renderNavItem()}

              {/* <li>
                <Link to="/brands/" activeStyle={activeLinkStyle}>
                  For Brands
                </Link>
              </li> */}


              {this.state.isAuthenticated ? (
                <li>
                  <ProfileButton>
                    <img
                      style={{ width: '26px', height: '26px' }}
                      src={this.state.image}
                      alt="Logo"
                    />
                    <span>{this.state.name}</span>
                  </ProfileButton>
                </li>
              ) : (
                <Fragment>
                  {/* 
                  <li>
                    <LoginButton onClick={this.openLoginModal}>
                      LOG IN
                    </LoginButton>
                  </li>
                  */}
                  <li>
                    <Button onClick={this.openInfluencerRegisterModal} className='signup-button' padding="11px 28.5px">SIGN UP</Button>
                  </li>
                </Fragment>
              )}

              {/* <li>
                <a href="https://go.oncehub.com/Embello" target="_blank" rel="noopener noreferrer">
                  <Button className='tertiary rounded signup-button' padding="11px 28.5px">CONTACT US</Button>
                </a>
              </li> */}
            </ul>
          </Nav>
        </NavContainer>

        <Modal visible={this.state.showLoginModal} type="login">
          <ModalContainer>
            <ModalNavContainer className="block">
              {/* <ModalLogo>
                <Link to="/" className="logo" activeStyle={activeLinkStyle}>
                  <img
                    src="/logos/EmbelloLogo_NoTagline_Black.svg"
                    style={{ width: '156px' }}
                    alt="EMBELLO"
                  />
                </Link>
              </ModalLogo> */}
              <ModalImage>
                  <img 
                    src="/icons/icon-welcome.svg"
                    style={{ maxWidth: '87.05px', margin: '0 auto' }} 
                    alt="Welcome"/>
              </ModalImage>
              <CloseModal>
                <img
                  onClick={this.closeModal}
                  src="/icons/icon-close.svg"
                  style={{ width: '20.01px' }}
                  alt="close icon"
                  />
              </CloseModal>
            </ModalNavContainer>
            <ModalBodyContainer>
              <ModalTitle align="center">WELCOME TO EMBELLO</ModalTitle>
              <ModalSubTitle align="center" className="questionnaire">
                {this.state.typeLogin === 'BRAND' ?
                  'Next step is to complete our quick questionnaire so we can send over quote.'
                  :
                  'Next step is to complete our quick questionnaire so we can get to know you and match you with the right campaign opportunities.' 
                }
              </ModalSubTitle>
              <RowForm paddingTop="80px">
                <Link to={`/signup/${this.state.typeLogin === 'BRAND' ? 'bform' : 'iform'}`}>
                  <Button
                    minWidth="100%"
                    padding="13.5px 12px" 
                    borderRadius="6.28832px"
                    className="tertiary medium">
                    FILL OUT QUICK QUESTIONNAIRE
                  </Button>
                </Link>
              </RowForm>
              <RowForm paddingTop="20px">
                <Button 
                  minWidth="100%"
                  padding="13.5px 12px" 
                  borderRadius="6.28832px"
                  className="tertiary medium rounded"
                  type="button"
                  onClick={this.closeModal}>
                  I'LL COMPLETE LATER
                </Button>
              </RowForm>
              <ModalFooter paddingTop="58px">
                Contact us at{' '}
                <a href="mailto:hello@embello.com" target="_top">
                  hello@embello.com
                </a>{' '}
                if you'd like help in creating your profile.
              </ModalFooter>
              {/* <ModalFooterConfirm>
                Responses will only be saved after hitting “submit” at the end of
                the questionnaire.
              </ModalFooterConfirm> */}
            </ModalBodyContainer>
          </ModalContainer>
        </Modal>

        <Modal visible={this.state.showRegisterModal} type="register">
          <ModalNavContainer>
            <ModalLogo>
              <Link to="/" className="logo" activeStyle={activeLinkStyle}>
                <img
                  src="/logos/EmbelloLogo_NoTagline_Black.svg"
                  style={{ width: '156px' }}
                  alt="EMBELLO"
                />
              </Link>
            </ModalLogo>
            <CloseModal>
              <img
                onClick={this.closeModal}
                src="/logos/icon_close.png"
                style={{ width: '30.4px' }}
                alt="close icon"
              />
            </CloseModal>
          </ModalNavContainer>
          <ModalTitle>JOIN OUR COMMUNITY</ModalTitle>
          <ModalSubTitle />
          <ModalLoginLink>
            <a href="https://go.oncehub.com/Embello" target="_blank">
              SIGN UP AS A BRAND
            </a>
          </ModalLoginLink>
          <ModalLoginLink>
            <a onClick={this.openInfluencerRegisterModal}>
              SIGN UP AS AN INFLUENCER
            </a>
          </ModalLoginLink>
          <ModalFooter>
            By signing up, you agree to our{' '}
            <Link to="/termsofuse/">Terms of Use</Link> and have read and
            acknowledge our <Link to="/privacypolicy/">Privacy Policy</Link>.
          </ModalFooter>
        </Modal>

        <Modal visible={this.state.showInfluencerRegisterModal} type="register">
          <ModalContainer>
            <ModalNavContainer>
              {/* <ModalLogo>
                <Link to="/" className="logo" activeStyle={activeLinkStyle}>
                  <img
                    src="/logos/EmbelloLogo_NoTagline_Black.svg"
                    style={{ width: '156px' }}
                    alt="EMBELLO"
                  />
                </Link>
              </ModalLogo> */}
              <ModalTitle>SIGN UP</ModalTitle>
              <CloseModal>
                <img
                  onClick={this.closeModal}
                  src="/icons/icon-close.svg"
                  style={{ width: '20.01px' }}
                  alt="close icon"
                />
              </CloseModal>
            </ModalNavContainer>
            <ModalBodyContainer>
              <ModalSubTitle align="left">
                Sign up today to join our exclusive community of influencers and top brands in the home + design industry.
              </ModalSubTitle>
              <FormDiv>
              <div id="mc_embed_signup">

                <form 
                  onSubmit={this._handleSubmit}
                  id="mc-embedded-subscribe-form" 
                  name="mc-embedded-subscribe-form"
                  className="validate"
                  noValidate
                >
              
                  <Label>I am signing up as a:</Label>
                  
                  <RowButtom>
                    <ColumnButton>
                    <Link to="/signup/iformBrand" className="button-content">  
                      <Button 
                        
                        minWidth="100%"
                        padding="11.6px 20px" 
                        borderRadius="6.28832px"
                        className={`tertiary medium ${this.state.typeLogin === 'BRAND' ? '' : 'rounded'}`}>
                        BRAND
                      </Button>
                      </Link>
                    </ColumnButton>
                    <ColumnButton>
                      {/* <Button 
                        onClick={() => this.changeTypeLogin('INFLUENCER')}
                        minWidth="100%"
                        padding="11.6px 12px" 
                        borderRadius="6.28832px"
                        className={`tertiary medium ${this.state.typeLogin === 'INFLUENCER' ? '' : 'rounded'}`}>
                        INFLUENCER
                      </Button> */}
                      <Link to="/signup/iform" className="button-content">  
                        <Button
                          minWidth="100%"
                          padding="11.6px 12px" 
                          borderRadius="6.28832px"
                          className="tertiary medium rounded">
                          INFLUENCER
                        </Button>
                      </Link>
                    </ColumnButton>
                  </RowButtom>
                  <RowForm>
                    {errors ? (
                      <FormMessage paddingTop={messages ? '15px' : '0px'} className="formError" dangerouslySetInnerHTML={{__html: (messages || '') }} />
                    ) : (
                      <FormMessage paddingTop={messages ? '15px' : '0px'}>{messages}</FormMessage>
                    )}
                  </RowForm>
                  <div style={{position: "absolute", left: "-5000px"}} aria-hidden="true">
                    <input type="text" name={`b_0eedca2625c79d77ed669f13d_${this.state.mailchimpListId}`} tabIndex="-1" />
                  </div>
                  
                </form>
              </div>
              </FormDiv>

              <ModalFooter>
                By signing up, you agree to our{' '}
                <Link to="/termsofuse/">Terms of Use</Link> and have read and
                acknowledge our <Link to="/privacypolicy/">Privacy Policy</Link>.
              </ModalFooter>
            </ModalBodyContainer>
          </ModalContainer>
        </Modal>

        <Modal visible={this.state.showInfluencerLoginModal} type="register">
          <ModalNavContainer>
            <ModalLogo>
              <Link to="/" className="logo" activeStyle={activeLinkStyle}>
                <img
                  src="/logos/EmbelloLogo_NoTagline_Black.svg"
                  style={{ width: '156px' }}
                  alt="EMBELLO"
                />
              </Link>
            </ModalLogo>
            <CloseModal>
              <img
                onClick={this.closeModal}
                src="/logos/icon_close.png"
                style={{ width: '30.4px' }}
                alt="close icon"
              />
            </CloseModal>
          </ModalNavContainer>
          <ModalTitle>LOG IN</ModalTitle>
          <ModalSubTitle3>
            Not a member?{' '}
            <a onClick={this.openInfluencerRegisterModal}>Sign up</a>
          </ModalSubTitle3>

          <InstagramLogin
            cssClass="instagram-button"
            clientId="63606ac3ac8b4a0dab5423ba04c9ce4c"
            scope="follower_list"
            buttonText="Log in with Instagram"
            onSuccess={this.responseInstagram}
            onFailure={this.responseInstagram}
            implicitAuth={false}
            redirectUri={''}
          >
            Log in with Instagram
          </InstagramLogin>

          <FacebookLogin
            cssClass="facebook-button"
            appId="2150500381870836"
            autoLoad={false}
            fields="name,email,picture"
            callback={this.responseFacebook}
            render={renderProps => (
              <ModalFacebookLink onClick={renderProps.onClick}>
                Log in with Facebook
              </ModalFacebookLink>
            )}
          />

          <ModalFooter>
            By logging in, you agree to our{' '}
            <Link to="/privacypolicy/">Terms of Use</Link> and have read and
            acknowledge our <Link to="/termsofuse/">Privacy Policy</Link>.
          </ModalFooter>
        </Modal>
      </Header>
      <div dangerouslySetInnerHTML={this.malChimpForm()}/>
      </>
    )
  }
}

class Menu extends React.Component {

  constructor(props) {
    super(props)
    this.menuRef = React.createRef();
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            contentfulMainMenu(version: { eq: "current" }) {
              elements {
                title
                action
                isExternalLink
                isParent
                elements {
                  title
                  action
                  isExternalLink
                  isParent
                }
              }
            }
          }
        `}
        render={data => <PureMenu data={data} {...this.props} ref={this.menuRef}/>}
      />
    )
  }
}

export default Menu
